/* eslint-disable */

import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import dom from "react-dom-factories";
import $ from "jquery";
import { getVideo, getVideoKaltura } from "services/video";
import _each from "lodash-amd/modern/collections/forEach";
import "../../support/digital-data/handler/video";
const trigger = require("support/utilities").trigger;

var paywallEnabled = false;

const VideoEmbed = createReactClass({
	getInitialState: function () {
		return { video: null, videoKaltura: null };
	},

	componentDidMount: function () {
		if (
			typeof this.props.name != "undefined" &&
			this.props.kaltura == "embedded"
		) {
			getVideoKaltura(this.props.name).then(
				(response) => {
					response.json().then((videoRes) => {
						this.setState({ videoKaltura: videoRes });
					});
				},
				(error) => {
					console.error("error", error);
				}
			);
		} else {
			if (typeof this.props.name != "undefined") {
				getVideo(this.props.name).then((video) =>
					this.setState({ video: video })
				);
			} else {
				getVideo(window.location.pathname.match(/\d+/)[0]).then((video) =>
					this.setState({ video: video })
				);
			}
		}
	},

	componentDidUpdate: function (prevProps, prevState) {
		const video = this.state.video;
		const videoKaltura = this.state.videoKaltura;

		//kaltura----------------------------------------
		if (videoKaltura && !prevState.videoKaltura && !paywallEnabled) {
			const hbrPlayButton = document.createElement("button");
			hbrPlayButton.className = "video-overlay-play-button";
			hbrPlayButton.innerHTML =
				'<svg class="video-overlay-play-button-icon"><use xlink:href="/resources/css/images/hbr-icons.svg#play-icon"></use></svg>';

			const videoOverlayContainer = document.createElement("div");
			videoOverlayContainer.className = "video-overlay";
			videoOverlayContainer.appendChild(hbrPlayButton);
			$(".vjs-poster").css("opacity", "1");
			this.bindInteractionEvents();
		}
		//end kaltura------------------------------------

		if (video && !prevState.video && !paywallEnabled) {
			const videoTag = ReactDOM.findDOMNode(this).querySelector("video");
			videoTag.setAttribute("data-account", "2071817190001");
			videoTag.setAttribute(
				"data-player",
				"e17e6e1b-ffd1-4650-843d-76760bab288b"
			);
			videoTag.setAttribute("data-video-id", video.id);
			bc(videoTag);

			const theVideoPlayButton = document.querySelector(
				"#the-video > .vjs-big-play-button"
			);

			while (theVideoPlayButton.firstChild) {
				theVideoPlayButton.removeChild(theVideoPlayButton.firstChild);
			}

			theVideoPlayButton.classList.remove("vjs-big-play-button");
			theVideoPlayButton.classList.add("hbr-play-button");

			const hbrPlayButton = document.createElement("button");
			hbrPlayButton.className = "video-overlay-play-button";
			hbrPlayButton.innerHTML =
				'<svg class="video-overlay-play-button-icon"><use xlink:href="/resources/css/images/hbr-icons.svg#play-icon"></use></svg>';

			const videoOverlayContainer = document.createElement("div");
			videoOverlayContainer.className = "video-overlay";
			videoOverlayContainer.appendChild(hbrPlayButton);

			theVideoPlayButton.appendChild(videoOverlayContainer);

			const videoPlayer = videojs("the-video");
			videoPlayer.on("ended", () => {
				trigger("metrics:video_complete", { video: this.state.video });
			});
			// for article-page we need to set opacity 1 so
			// the video embedded on the article page gets displayed
			// with the poster
			$(".vjs-poster").css("opacity", "1");
			// bind interaction events for digital data
			this.bindInteractionEvents();
		}
	},

	bindInteractionEvents: function () {
		if (this.state.video && this.state.video.name) {
			var videoTitle = this.state.video.name;
			const videoPlayer = videojs("the-video");
			videoPlayer.on("firstplay", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Start",
					window.parent,
				]);
			});
			videoPlayer.on("pause", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Pause",
					window.parent,
				]);
			});
			videoPlayer.on("ended", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Complete",
					window.parent,
				]);
			});
			videoPlayer.on("play", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Play",
					window.parent,
				]);
				trigger("metrics:video_start", { video: this.state.video });
			});
			videoPlayer.on("volumechange", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Volume Change",
					window.parent,
				]);
			});
			videoPlayer.on("fullscreenchange", (e) => {
				var eventAction = "Fullscreen Enter";
				if (!videoPlayer.isFullscreen()) {
					eventAction = "Fullscreen Exit";
				}
				$("body").trigger("video-interaction", [
					videoTitle,
					eventAction,
					window.parent,
				]);
			});
			videoPlayer.on("error", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Error",
					window.parent,
				]);
			});
			videoPlayer.on("playing", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Play",
					window.parent,
				]);
			});
		}
	},

	render: function () {
		const video = this.state.video;
		if (video) {
			return this.props.poster
				? dom.img({ className: "relative", src: video.poster })
				: dom.div({
						className: "relative",
						style: { paddingTop: "56.25%" },
						dangerouslySetInnerHTML: {
							__html: paywallEnabled
								? '<img src="' +
								  video.poster +
								  '" style="width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; right: 0; left: 0">'
								: '<video id="the-video" data-embed="default" class="video-js" controls="" style="width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; right: 0; left: 0"></video>',
						},
				  });
		}

		const videoKaltura = this.state.videoKaltura;
		if (videoKaltura) {
			const currentLocation = window.location.href;
			return this.props.poster
				? dom.img({ className: "relative", src: videoKaltura.thumbnailUrl })
				: dom.div({
						className: "relative",
						style: { paddingTop: "56.25%" },
						dangerouslySetInnerHTML: {
							__html: paywallEnabled
								? '<img src="' +
								  videoKaltura.thumbnailUrl +
								  '" style="width: 100%; height: 100%; bottom: 0; right: 0; left: 0">'
								: `<div id="kaltura_player_${videoKaltura.id}_embedded_video" style="width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; right: 0; left: 0;">
										<iframe
											type="text/javascript" 
											style="width: 100%; height: 100%;"
											allowfullscreen 
											webkitallowfullscreen 
											mozAllowFullScreen frameborder="0" 
											allow="fullscreen *; accelerometer *; autoplay *; encrypted-media *; gyroscope *; picture-in-picture *" 
											srcdoc='
												<head>
												<style>
													html {height: 100%; width: 100%;}
													body {margin: 0; padding: 0; height: 100%; width: 100%;}
													@media only screen and (max-width: 485px) {.playkit-overlay.playkit-share-overlay .playkit-overlay-contents .overlay-screen {transform: scale(0.5) !important;}}
												</style>
												</head>
												<div id="kaltura-id" style="width:100%;height:100%;position:fixed;"></div>
												<script type="text/javascript" src="https://cdnapisec.kaltura.com/p/506471/embedPlaykitJs/uiconf_id/55694072"></script>
												<script type="text/javascript">
												try {
													var kalturaPlayer = KalturaPlayer.setup({
													targetId: "kaltura-id",
													provider: {
														partnerId: 506471,
														uiConfId: 55694072
													},
													playback: {
														autoplay: false
													},
													plugins: {
														share: {
															shareUrl: "${currentLocation}"
														}
													}
													});
													kalturaPlayer.loadMedia({entryId: "${videoKaltura.id}"});
												} catch (e) {
													console.error("kaltura error", e.message);
												}
												</script>
											'
										></iframe>
								   </div>`,
						},
				  });
		}

		return null;
	},
});

VideoEmbed.activateAll = () => {
	const poster = false;
	const dictPoster = { poster: poster };
	var element = document.querySelector(
		".hero-image-content [js-target='video-player-page']"
	);
	if (element) {
		var paywallAttribute = element.getAttribute("paywall-enabled");
		if (paywallAttribute == "true") {
			paywallEnabled = true;
		} else {
			paywallEnabled = false;
		}
	}
	_each($("[js-target='video-player-page']"), (el) =>
		ReactDOM.render(
			React.createElement(
				VideoEmbed,
				Object.assign({}, dictPoster, $(el).data("props"))
			),
			el
		)
	);
};

/*	Attach handlers to video components of class sidebar--half or sidebar--half-right,
	which toggles class to "-expanded" (full-width) form when video starts	*/
export function expandAllSidebarVideos() {
	const halfSidebars = [
		...document.querySelectorAll(".sidebar--half, .sidebar--half-right"),
	];

	halfSidebars.filter((sidebar) => {
		if (
			sidebar.querySelector(".sidebar-cta-expand") ||
			sidebar.querySelector(".sidebar-expand")
		) {
			$("body").on("video-interaction", function (e, videoTitle, videoStatus) {
				if (videoStatus === "Play") {
					sidebar.className = sidebar.className.replace(
						/sidebar--half(?:-right)?/i,
						"sidebar"
					);
				}
			});
		}
	});
}

export default VideoEmbed;
