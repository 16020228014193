/* eslint-disable */

import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import dom from "react-dom-factories";
import $ from "jquery";
import moment from "moment";
import { getVideo, getPlaylist, videoUrl, isSubOnly } from "services/video";
import VideoPlaylist from "components/video/video-playlist";
import VideoStreamItem from "components/video/video-stream-item";
import VideoEndScreen from "components/video/video-end-screen";
import _each from "lodash-amd/modern/collections/forEach";
import _findIndex from "lodash-amd/modern/arrays/findIndex";
import _find from "lodash-amd/modern/collections/find";
import urlQueryStringParser from "../../support/urlparams";
import "../../support/digital-data/handler/video";
const trigger = require("support/utilities").trigger;

const defaultPlaylists = [
	["The Latest", "2366201073001"],
	["Most Popular", "2366201078001"],
	["Subscriber Only", "5846402052001"],
	["Explainers", "4490758417001"],
];

const VideoPlayerPage = createReactClass({
	getInitialState: function () {
		var playlistId = urlQueryStringParser.getUrlParamValue(
			window.location.search,
			"playlist"
		);
		return {
			video: null,
			playlist: null,
			playlistId:
				playlistId && playlistId.match(/[a-zA-Z0-9\-]+/) ? playlistId : null,
			playlistName: "",
			paywallEnabled: this.props.paywallEnabled === "true",
		};
	},

	componentDidMount: function () {
		if (this.state.playlistId) {
			var component = this;
			getPlaylist(this.state.playlistId).then(
				function (playlistData) {
					var requestedVideoId = window.location.pathname.match(/\d+/);
					requestedVideoId = requestedVideoId ? requestedVideoId[0] : "";
					var videoData = _find(playlistData.videos, function (playlistVideo) {
						return playlistVideo.id === requestedVideoId;
					});
					component.setState({
						video: videoData ? videoData : playlistData.videos[0],
						playlist: playlistData,
						playlistName: playlistData.name,
					});
				},
				function (error) {
					window.location.href = window.location.pathname;
				}
			);
		} else {
			getVideo(window.location.pathname.match(/\d+/)[0]).then((video) =>
				this.setState({ video: video })
			);
		}
	},

	componentDidUpdate: function (prevProps, prevState) {
		const video = this.state.video;
		if (window.history && window.history.pushState) {
			history.pushState(null, null, videoUrl(video));
		}
		if (video && !prevState.video && !this.state.paywallEnabled) {
			// const videoTag = ReactDOM.findDOMNode(this).querySelector("video");

			// videoTag.setAttribute("data-account", video.account_id);
			// videoTag.setAttribute("data-player", "default");
			// videoTag.setAttribute("data-video-id", video.id);
			// bc(videoTag);
			// videojs("the-video").on("ended", () => {
			// 	if (this.state.playlist) {
			// 		this.processNextVideo(this.state.playlist, video);
			// 	} else {
			// 		getPlaylist(video.custom_fields.hbrplaylist || "2366201073001").then(
			// 			(playlist) => {
			// 				this.processNextVideo(playlist, video);
			// 			}
			// 		);
			// 	}
			// });
			// videojs("the-video").on("firstplay", () => {
			// 	trigger("metrics:video_start", { video: this.state.video });
			// });
			// bind interaction events for digital data
			// this.bindInteractionEvents();
		}

		$(document).trigger("video-player-page:loaded");
	},

	bindInteractionEvents: function () {
		if (this.state.video && this.state.video.name) {
			var videoTitle = this.state.video.name;
			const videoPlayer = videojs("the-video");

			// Let's disable the sharing utilities on the brightcove player if the video is for subscribers only
			if (isSubOnly(this.state.video)) {
				// Quick check to ensure the video player and control bar has been initialised correctly
				if (typeof videoPlayer.controlBar.socialButton !== "undefined") {
					// Remove share button
					videoPlayer.controlBar.socialButton.dispose();
				}
			}

			videoPlayer.on("firstplay", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Start",
					window.parent,
				]);
			});
			videoPlayer.on("pause", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Pause",
					window.parent,
				]);
			});
			videoPlayer.on("ended", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Complete",
					window.parent,
				]);
			});
			videoPlayer.on("play", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Play",
					window.parent,
				]);
				trigger("metrics:video_start", { video: this.state.video });
			});
			videoPlayer.on("volumechange", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Volume Change",
					window.parent,
				]);
			});
			videoPlayer.on("fullscreenchange", (e) => {
				var eventAction = "Fullscreen Enter";
				if (!videoPlayer.isFullscreen()) {
					eventAction = "Fullscreen Exit";
				}
				$("body").trigger("video-interaction", [
					videoTitle,
					eventAction,
					window.parent,
				]);
			});
			videoPlayer.on("error", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Error",
					window.parent,
				]);
			});
			videoPlayer.on("playing", (e) => {
				$("body").trigger("video-interaction", [
					videoTitle,
					"Play",
					window.parent,
				]);
			});
		}
	},

	processNextVideo: function (playlist, video) {
		const nextVideo =
			playlist.videos[
				_findIndex(playlist.videos, (v) => v.id === video.id) + 1
			] || playlist.videos[0];
		const promise = $.Deferred();
		const container = videojs("the-video")
			.el()
			.appendChild(document.createElement("div"));
		trigger("metrics:video_complete", { video: this.state.video });
		promise.then((action) => {
			if (action === "replay") {
				ReactDOM.unmountComponentAtNode(container);
				container.remove();
				videojs("the-video").play();
			} else {
				window.location = videoUrl(nextVideo);
			}
		});
		ReactDOM.render(
			React.createElement(VideoEndScreen, {
				video: video,
				nextVideo: nextVideo,
				promise: promise,
			}),
			container
		);
	},

	render: function () {
		const currentLocation = window.location.href;
		const video = this.state.video;
		var videoContainerClass =
			"has-borders faint-drop-shadow backdrop-white video-container";
		return video
			? dom.div(
					{},
					dom.div(
						{
							className: this.state.paywallEnabled
								? videoContainerClass + " margin-bottom-normal"
								: videoContainerClass,
						},
						dom.div(
							{ className: "flex-row-medium" },
							dom.div(
								{
									className:
										"flex-2 justify-center has-border-right-for-medium-up has-border-bottom-for-small",
								},
								dom.div(
									{},
									// injecting as string because the player JS blindly clones DOM attributes, which creates duplicate data-reactid
									dom.div({
										className: "relative",
										style: { paddingTop: "56.25%" },
										dangerouslySetInnerHTML: {
											__html: this.state.paywallEnabled
												? '<img src="' +
												  video.poster +
												  '" style="width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; right: 0; left: 0"></video>'
												: `<div id="kaltura_player_${video.reference_id}_embedded_video" style="width: 100%; height: 100%; position: absolute; top: 0; bottom: 0; right: 0; left: 0;">
												  <iframe
													  type="text/javascript" 
													  style="width: 100%; height: 100%;"
													  allowfullscreen 
													  webkitallowfullscreen 
													  mozAllowFullScreen frameborder="0" 
													  allow="fullscreen *; accelerometer *; autoplay *; encrypted-media *; gyroscope *; picture-in-picture *" 
													  srcdoc='
														  <head>
														  <style>
															  html {height: 100%; width: 100%;}
															  body {margin: 0; padding: 0; height: 100%; width: 100%;}
														  	  @media only screen and (max-width: 485px) {.playkit-overlay.playkit-share-overlay .playkit-overlay-contents .overlay-screen {transform: scale(0.5) !important;}}
														  </style>
														  </head>
														  <div id="kaltura-id" style="width:100%;height:100%;position:fixed;"></div>
														  <script type="text/javascript" src="https://cdnapisec.kaltura.com/p/506471/embedPlaykitJs/uiconf_id/55694072"></script>
														  <script type="text/javascript">
														  try {
															  var kalturaPlayer = KalturaPlayer.setup({
															  targetId: "kaltura-id",
															  provider: {
																  partnerId: 506471,
																  uiConfId: 55694072
															  },
															  playback: {
																  autoplay: false
															  },
															  plugins: {
																share: {
																	shareUrl: "${currentLocation}"
																}
															  }
															  });
															  kalturaPlayer.loadMedia({entryId: "${video.reference_id}"});
														  } catch (e) {
															  console.error(e.message);
														  }
														  </script>
													  '
												  ></iframe>
											 </div>`,
										},
									})
								)
							),
							dom.div(
								{ className: "flex-1 pam" },
								dom.h2(
									{
										className:
											".line-height-more-snug font-size-24 spacing-narrow",
									},
									video.name
								),
								dom.div(
									{ className: "dek child-links--black" },
									dom.span({
										dangerouslySetInnerHTML: { __html: video.description },
									}),
									" ",
									video.link
										? [
												"For more, read ",
												dom.a({
													href: video.link.url,
													className: "is-underlined",
													dangerouslySetInnerHTML: { __html: video.link.text },
												}),
										  ]
										: null
								),
								dom.ul(
									{ className: "piped-inline-list flex-1" },
									dom.li(
										{ className: "pubdate" },
										moment(video.custom_fields.pubdate, "DD MMM YYYY").format(
											"MMMM DD, YYYY"
										)
									),
									dom.li(
										{ className: "text-gray font-size-13" },
										moment.duration(video.duration).format("h:mm:ss")
									)
								),
								React.createElement(
									VideoStreamItem,
									{ video: video },
									dom.div(
										{ className: "mtm" },
										dom.a(
											{
												href: "#",
												className: "link--black",
												"data-js-target": "save-flyout",
											},
											dom.i({
												className: "icon icon-file-text-o",
												title: "Save to my library",
											})
										),
										isSubOnly(video)
											? null
											: [
													dom.a(
														{
															href: "#",
															className: "link--black mll",
															"data-js-target": "share-flyout",
														},
														dom.i({
															className: "icon icon-share",
															title: "Share",
														})
													),
													dom.a({
														href: "#",
														className:
															"link--black mll icon-lt-gt font-size-18 font-light",
														"data-js-target": "embed-flyout",
														title: "Embed",
													}),
											  ]
									)
								)
							)
						)
					),
					this.state.paywallEnabled
						? null
						: React.createElement(VideoPlaylist, {
								name: this.state.playlistName
									? this.state.playlistName
									: "What to watch next",
								count: 8,
								omit: this.state.playlistId ? null : this.state.video,
								ad: this.state.playlistId ? false : true,
								playlist: this.state.playlistId
									? this.state.playlistId
									: video.custom_fields.hbrplaylist || "2366201073001",
								selections: this.state.playlistId
									? null
									: (video.custom_fields.hbrplaylist
											? [["Related", video.custom_fields.hbrplaylist]]
											: []
									  ).concat(defaultPlaylists),
						  })
			  )
			: null;
	},
});

VideoPlayerPage.activateAll = () => {
	_each($("[js-target='video-player-page']"), (el) =>
		ReactDOM.render(
			React.createElement(VideoPlayerPage, {
				paywallEnabled: $(el).attr("paywall-enabled"),
			}),
			el
		)
	);
};

export default VideoPlayerPage;
